<ng-container *ngIf="!showAsList; else listView">
    <div *ngIf="isAuthenticated" class="dropdown">
        <fa-icon icon="user-circle" [matMenuTriggerFor]="userMenu" id="menu-user-menu"></fa-icon>

        <mat-menu #userMenu="matMenu" yPosition="below" [overlapTrigger]="false" class="account-dropdown-menu">
            <div class="user-menu">
                <div class="profile-info menu-divider">
                    <div class="user-name">
                        <div class="user-circle">
                            <span>{{ user.initials }}</span>
                        </div>
                        <span>
                            {{ user.fullName }}
                            @if (user.usedMfa) {
                            <p class="login-type">(Logged in via OneID)</p>
                            }
                        </span>
                    </div>

                    <a routerLink="/app/user/profile" class="profile-link extra-padding">Manage your account</a>
                </div>

                <div class="admin-tools menu-divider">
                    <a mat-menu-item *ngIf="isImpersonating" (click)="onUnimpersonate()">
                        <fa-icon icon="sign-out-alt"></fa-icon>
                        <span> Stop Impersonating</span>
                    </a>

                    <button *ngIf="isSystemNewsReporter || isUserManager" mat-menu-item (click)="sendEmail()">
                        <fa-icon icon="envelope"></fa-icon>
                        <span> Email All Users</span>
                    </button>

                    <button *ngIf="showManagementLinks()" mat-menu-item [matMenuTriggerFor]="managementMenu">
                        <fa-icon icon="user-shield"></fa-icon>
                        <span> Management</span>
                    </button>

                    <button mat-menu-item *ngIf="isAdmin" [matMenuTriggerFor]="trainingMenu">
                        <fa-icon icon="person-chalkboard"></fa-icon>
                        <span> Training</span>
                    </button>

                    <eaglei-stop-time *ngIf="canLockTime$ | async"></eaglei-stop-time>
                </div>

                <div class="notifications menu-divider">
                    <button mat-menu-item (click)="openApiRequestModal()">
                        <div>
                            <fa-icon icon="key"></fa-icon>
                            <span>Request API Key </span>
                        </div>
                    </button>

                    <button *ngIf="!useMobileLayout()" mat-menu-item routerLink="/app/alerts">
                        <fa-icon icon="bell"></fa-icon>
                        Alerts
                    </button>

                    <button *ngIf="!useMobileLayout()" mat-menu-item routerLink="/app/user/subscription">
                        <fa-icon icon="envelope-open"></fa-icon>
                        Report Subscription
                    </button>
                </div>

                <div class="logout">
                    <button mat-menu-item (click)="onLogout()">
                        <fa-icon icon="arrow-right-from-bracket"></fa-icon>
                        <span>Log out</span>
                    </button>
                </div>
            </div>
        </mat-menu>

        <mat-menu #managementMenu="matMenu">
            <a mat-menu-item *ngIf="isUserManager" routerLink="/app/user/manage">
                <fa-icon icon="user-plus"></fa-icon>
                <span>User</span>
            </a>
            <a mat-menu-item *ngIf="isContentManager" routerLink="/app/layers/manage">
                <fa-icon icon="map"></fa-icon>
                <span>Layer</span>
            </a>
            <a mat-menu-item *ngIf="isContentManager" routerLink="/app/reports/manage">
                <fa-icon icon="chart-line"></fa-icon>
                <span>Report</span>
            </a>
            <a mat-menu-item *ngIf="isApiAccessible" routerLink="/app/apikey/manage">
                <fa-icon icon="key"></fa-icon>
                <span>API-Key</span>
            </a>
            <a mat-menu-item *ngIf="isOutageAdmin" routerLink="/app/management/subutility">
                <fa-icon icon="industry"></fa-icon>
                <span>SubUtility</span>
            </a>
        </mat-menu>
    </div>
</ng-container>
<ng-template #listView>
    <div class="mobile-list">
        <div class="mobile-profile-info">
            <div>
                <fa-icon icon="user-circle"></fa-icon>
                <span>{{ user.fullName }}</span>
            </div>
            <a routerLink="/app/user/profile" class="profile-link">Manage your account</a>
        </div>

        <a *ngIf="isImpersonating" mat-menu-item (click)="onUnimpersonate()">
            <fa-icon icon="sign-out-alt"></fa-icon>
            Stop Impersonating
        </a>

        <div class="management-list" *ngIf="showManagementLinks()">
            <div (click)="showManagementMenu = !showManagementMenu">
                <div>
                    <fa-icon icon="user-shield"></fa-icon>
                    <span>Management</span>
                </div>
                <fa-icon icon="chevron-down" class="no-margin"></fa-icon>
            </div>

            <div class="hide" [class.show]="showManagementMenu">
                <ng-container *ngFor="let item of managementMenuItems">
                    <div class="management-item" *ngIf="item.access()" [routerLink]="item.url">
                        <fa-icon [icon]="[item.set, item.icon]"></fa-icon>
                        <span>{{ item.name }}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <a *ngIf="isSystemNewsReporter || isUserManager" (click)="sendEmail()" class="menu-divider">
            <fa-icon icon="envelope"></fa-icon>
            Email All Users
        </a>

        <a
            *ngIf="!useMobileLayout(true)"
            matTooltip="Request Pending"
            matTooltipShowDelay="250"
            matTooltipPosition="before"
            [matTooltipDisabled]="!user.pendingAPIKeyRequest">
            <div (click)="openApiRequestModal()">
                <fa-icon icon="key"></fa-icon>
                Request API Key
            </div>
        </a>

        <a *ngIf="!useMobileLayout()" routerLink="/app/alerts" class="menu-divider">
            <fa-icon icon="bell"></fa-icon>
            Alerts
        </a>

        <a *ngIf="!useMobileLayout()" routerLink="/app/user/subscription" class="menu-divider">
            <fa-icon icon="envelope-open"></fa-icon>
            Report Subscription
        </a>

        <a (click)="onLogout()">
            <fa-icon icon="arrow-right-from-bracket"></fa-icon>
            Log out
        </a>
    </div>
</ng-template>

<mat-menu #trainingMenu="matMenu">
    <a mat-menu-item class="menu-item" routerLink="/app/training/spatial-upload">
        <fa-icon icon="upload"></fa-icon>
        <span>Spatial Upload</span>
    </a>
</mat-menu>
